////////// INIT GSAP ////////////////////////////

// import { gsap } from "gsap"
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { Draggable } from 'gsap/Draggable'
// import { InertiaPlugin } from './InertiaPlugin.min.js'
// gsap.registerPlugin(ScrollTrigger, Draggable, InertiaPlugin)


///////////////////////////////////////////////////////////////////
//////// A N I M A T I O N S //////////////////////////////////////
///////////////////////////////////////////////////////////////////

//------- TYPEWRITER
let hero_title = document.querySelector('[data-hero-title]');
if (hero_title) {
  let text = undefined

  function createText() {
    text = document.createElement('span')
    text.classList.add('hero__anim')
    text.setAttribute('data-typing-text', '')
    hero_title.querySelector('span:first-child').after(text)
  }

  // function destroyText() {
  //   text = undefined
  // }

  createText()

  // let observer = new IntersectionObserver((entries) => {
  //   let entry = entries[0]
  //   console.log(entry)
  //   if (entry.isIntersecting) createText()
  //   else if (!entry.isIntersecting) destroyText()

  // }, {
  //   root: document,
  //   rootMargin: '100px 0%'
  // })

  // observer.observe(hero_title)


  let words_list = Array.from(document.querySelectorAll('[data-words-list] li'))
  let words = words_list.map(word => { return word.innerText })

  setTyper(text, words);

  function setTyper(element, words) {
    const LETTER_TYPE_DELAY = 75;
    const WORD_STAY_DELAY = 2000;
    const DIRECTION_FORWARDS = 0;
    const DIRECTION_BACKWARDS = 1;
    var direction = DIRECTION_FORWARDS;
    var wordIndex = 0;
    var letterIndex = 0;
    var wordTypeInterval;
    startTyping();

    function startTyping() {
      wordTypeInterval = setInterval(typeLetter, LETTER_TYPE_DELAY);
    }

    function typeLetter() {
      const word = words[wordIndex];
      if (direction == DIRECTION_FORWARDS) {
        letterIndex++;
        if (letterIndex == word.length) {
          direction = DIRECTION_BACKWARDS;
          clearInterval(wordTypeInterval);
          setTimeout(startTyping, WORD_STAY_DELAY);
        }
      } else if (direction == DIRECTION_BACKWARDS) {
        letterIndex--;
        if (letterIndex == 0) {
          nextWord();
        }
      }
      const textToType = word.substring(0, letterIndex);
      element.textContent = textToType;
    }

    function nextWord() {
      letterIndex = 0;
      direction = DIRECTION_FORWARDS;
      wordIndex++;
      if (wordIndex == words.length) {
        wordIndex = 0;
      }
    }
  }
}

//------- SERVICES
let service_cards = document.querySelectorAll('.card-service')
if (service_cards) {
  let ww = window.innerWidth
  let observer = null

  if (ww > 618) {
    service_cards.forEach(card => {
      card.addEventListener('mouseenter', handleEnter)
      card.addEventListener('mouseleave', handleLeave)
    })
  } else {
    if ("IntersectionObserver" in window) createIO()
  }

  function createIO() {
    observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) handleEnterIO(entry.target)
        else handleLeaveIO(entry.target)
      })
    }, {
      root: document,
      rootMargin: '-46% 0%'
    })
    service_cards.forEach(card => {
      observer.observe(card)
    })
  }

  window.addEventListener('resize', () => {
    let cur_ww = window.innerWidth
    if (ww > 618 && cur_ww <= 618) { //  <= 618
      service_cards.forEach(card => {
        card.removeEventListener('mouseenter', handleEnter)
        card.removeEventListener('mouseleave', handleLeave)
      })
      if (!observer) createIO()
      else {
        service_cards.forEach(card => {
          observer.observe(card)
        })
      }
      ww = cur_ww
    } else if (ww <= 618 && cur_ww > 618) { //  > 618
      service_cards.forEach(card => {
        card.addEventListener('mouseenter', handleEnter)
        card.addEventListener('mouseleave', handleLeave)
      })
      service_cards.forEach(card => {
        observer.unobserve(card)
      })
      ww = cur_ww
    }
  })

  function handleEnter(e) {
    let x = randomInteger(0, 50)
    let y = randomInteger(0, -50)
    let deg = randomInteger(-30, 30)
    let img = e.currentTarget.querySelector('.card-service__img')
    img.style.transform = `translate(${x}px,${y}px) rotate(${deg}deg)`
  }

  function handleLeave(e) {
    let img = e.currentTarget.querySelector('.card-service__img')
    img.style.transform = `translate(0px,0px) rotate(0deg)`
  }

  function handleEnterIO(el) {
    let x = randomInteger(0, 50)
    let y = randomInteger(0, -50)
    let deg = randomInteger(-30, 30)
    el.classList.add('show-img')
    let img = el.querySelector('.card-service__img')
    img.style.transform = `translate(${x}px,${y}px) rotate(${deg}deg)`
  }

  function handleLeaveIO(el) {
    el.classList.remove('show-img')
    let img = el.querySelector('.card-service__img')
    img.style.transform = `translate(0px,0px) rotate(0deg)`
  }

  function randomInteger(min, max) {
    var rand = min + Math.random() * (max - min)
    rand = Math.round(rand);
    return rand;
  }
}

///////////////////////////////////////////////////////////////////
//////// H E A D E R //////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

//------ PRIM-MENU
let header_nav = document.querySelector("[data-header-nav]")
if (header_nav) {
  let burger_menu_block = document.querySelector("[data-burger-menu-block]")
  let burger = document.querySelector("[data-burger]")
  burger.addEventListener("click", (e) => {
    e.currentTarget.classList.toggle("toggle")
    if (window.innerWidth > 768) {
      burger_menu_block.classList.toggle("toggle")
    } else {
      header_nav.classList.toggle("toggle")
    }
  })

  window.addEventListener("click", e => {
    if (burger_menu_block.classList.contains("toggle")) {
      const b_m_b_dimentions = burger_menu_block.getBoundingClientRect()
      if (
        e.clientX < b_m_b_dimentions.left ||
        e.clientX > b_m_b_dimentions.right ||
        e.clientY < b_m_b_dimentions.top ||
        e.clientY > b_m_b_dimentions.bottom
      ) {
        burger.classList.toggle("toggle")
        burger_menu_block.classList.toggle("toggle")
      }
    }
    if (header_nav.classList.contains("toggle")) {
      const h_n_dimentions = header_nav.getBoundingClientRect()
      if (
        e.clientX < h_n_dimentions.left ||
        e.clientX > h_n_dimentions.right ||
        e.clientY < h_n_dimentions.top ||
        e.clientY > h_n_dimentions.bottom
      ) {
        burger.classList.toggle("toggle")
        header_nav.classList.toggle("toggle")
      }
    }
  })
  window.addEventListener('resize', () => {
    burger.classList.remove("toggle")
    burger_menu_block.classList.remove("toggle")
    header_nav.classList.remove("toggle")
  })
}

//------ LANGUAGE SWITCHER
let lang_switch = document.querySelector('[data-lang]')
if (lang_switch) {
  let ww = window.innerWidth
  let main = document.querySelector('main')
  let langs = lang_switch.querySelectorAll('button')
  langs.forEach(btn => btn.addEventListener('click', () => {
    if (btn.classList.contains('active')) return
    if (!btn.classList.contains('active')) {
      langs.forEach(btn => {
        btn.classList.toggle('active')
      })
    }
  }))

  if (ww > 768) {
    window.addEventListener('scroll', scrollLang)
  }


  function scrollLang() {
    if (main.getBoundingClientRect().top < -100) {
      if (lang_switch.classList.contains('hidden')) return
      lang_switch.classList.add('hidden')
    }
    if (main.getBoundingClientRect().top >= -100) {
      if (!lang_switch.classList.contains('hidden')) return
      lang_switch.classList.remove('hidden')
    }
  }

  window.addEventListener('resize', () => {
    let cur_ww = window.innerWidth
    if (ww > 768 && cur_ww <= 768) {
      window.removeEventListener('scroll', scrollLang)
      ww = cur_ww
    } else if (ww <= 768 && cur_ww > 768) {
      window.addEventListener('scroll', scrollLang)
      ww = cur_ww
    }
  })
}

///////////////////////////////////////////////////////////////////
//////// H E R O //////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

let video_overlay = document.querySelector('[data-video-overlay]')
if (video_overlay) {
  video_overlay.addEventListener('click', (e) => {
    let youtube_iframe = video_overlay.previousElementSibling
    let src = youtube_iframe.getAttribute('src')
    youtube_iframe.setAttribute('src', `${src}&autoplay=1`)
    e.currentTarget.remove()
  })
}

///////////////////////////////////////////////////////////////////
//////// L I G H T B O X //////////////////////////////////////////
///////////////////////////////////////////////////////////////////
import SimpleLightbox from "simplelightbox";

//---------- GALLERY --------------------------------------------//
let gal = document.querySelector('.gallery')
if (gal) {
  new SimpleLightbox('.gallery a', {
    overlayOpacity: .85,
    //   spinner: false,
    // nav: false,
    //   close: false,
    // showCounter: false,
    //   heightRatio: 1,
    //   widthRatio: 1
  })
}

///////////////////////////////////////////////////////////////////
//////// A C C O R D I O N S //////////////////////////////////////
///////////////////////////////////////////////////////////////////
// import Accordion from 'accordion-js'

//////// SEARCH RESULTS ACCORDIONS /////////////////////////////////
// const accordion_container = document.querySelector('.accordion-container')
// if (accordion_container) {
//   new Accordion('.accordion-container', {
// openOnInit: [0],
//   })
// }

///////////////////////////////////////////////////////////////////
/////////////// T A B S ///////////////////////////////////////////
///////////////////////////////////////////////////////////////////



///////////////////////////////////////////////////////////////////
// //////////// S L I D E R S /////////////////////////////////////
///////////////////////////////////////////////////////////////////

// import Splide, { LOOP } from '@splidejs/splide';
//-------------- NAV-CATEGORY MENU SLIDER  ---------------------------//

// var slider_nav_category = document.querySelector(".slider-nav-category-menu");
// if (slider_nav_category) {
// console.log(`slider is present`);

//   var slider = new Splide('.slider-nav-category-menu', {
//     autoWidth: true,
//     focus: 0,
//     arrows: true,
//     pagination: false,
//     breakpoints: {
//       1000: {
//         arrows: false,
//       },
//     }
//   });
//   slider.mount();

// } else {
//   console.log(`slider is NOT present`);
// }

import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Grid } from 'swiper/modules';

//----- TESTIMONIALS SLIDER
let testims_slider = document.querySelector('[data-testims-slider]')
if (testims_slider) {
  let swiper

  if (window.innerWidth > 428) {
    createSwiper()
  }

  function createSwiper() {
    swiper = new Swiper(testims_slider, {
      modules: [Navigation],
      loop: true,
      navigation: {
        nextEl: '.btn-slider--next',
        prevEl: '.btn-slider--prev',
      },
    })
  }
  window.addEventListener('resize', () => {
    if (window.innerWidth <= 428 && swiper) {
      swiper.disable()
    } else {
      if (!swiper) {
        createSwiper()
      } else {
        swiper.enable()
      }
    }
  })

  const texts = document.querySelectorAll('.testims__slide-text');
  texts.forEach(text => {
    text.addEventListener('click', (e) => {
      if (window.innerWidth <= 428) {
        e.currentTarget.classList.toggle('expanded')
      }
    })
  })
}

//----- CASESS SLIDER
let cases_slider = document.querySelector('[data-cases-slider]')
if (cases_slider) {
  let swiper

  if (window.innerWidth > 510) {
    createSwiper()
  }

  function createSwiper() {
    swiper = new Swiper(cases_slider, {
      modules: [Navigation],
      loop: true,
      breakpoints: {
        428: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: '.btn-slider--next',
        prevEl: '.btn-slider--prev',
      },
    })
  }
  window.addEventListener('resize', () => {
    if (window.innerWidth <= 510 && swiper) {
      swiper.disable()
    } else {
      if (!swiper) {
        createSwiper()
      } else {
        swiper.enable()
      }
    }
  })
}

//----- CLIENTS SLIDERS
let clients_slider_1 = document.querySelector('[data-clients-slider1]')
if (clients_slider_1) {
  let swiper = new Swiper(clients_slider_1, {
    modules: [Autoplay],
    loop: true,
    speed: 5000,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1.5,
      },
      390: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1025: {
        slidesPerView: 3.5,
      },
    }
  })
}

let clients_slider_2 = document.querySelector('[data-clients-slider2]')
if (clients_slider_2) {
  let swiper = new Swiper(clients_slider_2, {
    modules: [Autoplay],
    loop: true,
    speed: 5000,
    allowTouchMove: false,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
      reverseDirection: true
    },
    breakpoints: {
      320: {
        slidesPerView: 1.5,
        // spaceBetween: 20,
      },
      390: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1025: {
        slidesPerView: 3.5,
      },
    }
  })
}
//----- AWARDS SLIDER
let awards_slider = document.querySelector('[data-awards-slider]')
if (awards_slider) {
  const swiper = new Swiper(awards_slider, {
    modules: [Navigation, Grid],
    // loop: true,
    grid: {
      rows: 2,
      fill: 'row'
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
      },
    },
    navigation: {
      nextEl: '.btn-slider--next',
      prevEl: '.btn-slider--prev',
    },
  })
}

//----- CASE SLIDER
let case_slider = document.querySelector("[data-case-slider]")
if (case_slider) {
  let ww = window.innerWidth
  let slides, prev, next, slides_num, slides_to_show

  if (window.innerWidth > 768) {
    initSlider()
  } else {
    destroySlider()
  }

  function destroySlider() {
    slides.forEach(slide => {
      slide.classList.remove('gap', 'hidden', 'wide', 'narrow')
    })
    next.removeEventListener("click", onNext)
    prev.removeEventListener("click", onPrev)
    prev.disabled = false
    next.disabled = false
    slides = null, prev = null, next = null, slides_num = undefined, slides_to_show = undefined
  }

  function initSlider() {
    slides = Array.from(case_slider.querySelectorAll(".case-slider__slide"))
    prev = case_slider.querySelector(".prev")
    next = case_slider.querySelector(".next")
    next.addEventListener("click", onNext)
    prev.addEventListener("click", onPrev)
    slides_num = slides.length
    slides_to_show = 4
    prev.disabled = true
      // slides.forEach((slide) => slide.classList.add("hidden"))
    for (let [index, slide] of slides.entries()) {
      if (index < slides_to_show) {
        // slide.classList.remove("hidden")
        if (index === 0) slide.classList.add("wide")
        else slide.classList.add("narrow")
      }
      if (index < slides_to_show - 1) {
        slide.classList.add("gap")
      }
    }
  }

  function onNext(e) {
    if (prev.disabled === true) prev.disabled = false
    let slides_vis = Array.from(case_slider.querySelectorAll(".wide,.narrow"))
    let slide_vis_last = slides_vis[slides_vis.length - 1]
    if (slide_vis_last === slides[slides_num - 1]) return
    if (slide_vis_last.classList.contains("wide")) {
      rewriteClassesNextDirection(slides_vis)
      let last = case_slider.querySelector('.wide')
      if (last === slides[slides_num - 1]) {
        e.currentTarget.disabled = true
      }
      return
    }
    let slide_wide = case_slider.querySelector(".wide")
    slide_wide.classList.remove("wide")
    slide_wide.classList.add("narrow")
    let slide_next = slide_wide.nextElementSibling
    slide_next.classList.remove("narrow")
    slide_next.classList.add("wide")
  }

  function onPrev(e) {
    if (next.disabled === true) next.disabled = false
    let slides_vis = Array.from(case_slider.querySelectorAll(".wide,.narrow"))
    let slide_vis_first = slides_vis[0]
    if (slide_vis_first === slides[0]) return
    if (slide_vis_first.classList.contains("wide")) {
      rewriteClassesPrevDirection(slides_vis)
      let first = case_slider.querySelector('.wide')
      if (first === slides[0]) {
        e.currentTarget.disabled = true
      }
      return
    }
    let slide_wide = case_slider.querySelector(".wide")
    slide_wide.classList.remove("wide")
    slide_wide.classList.add("narrow")
    let slide_prev = slide_wide.previousElementSibling
    slide_prev.classList.remove("narrow")
    slide_prev.classList.add("wide")
  }

  function rewriteClassesNextDirection(arr) {
    arr.forEach((item, index) => {
      if (index === 0) {
        item.classList.remove('narrow')
        item.classList.remove('gap')
          // item.classList.add('hidden')
      }
      if (index === arr.length - 1) {
        item.classList.remove('wide')
        item.classList.add('narrow')
        item.classList.add('gap')
        item.nextElementSibling.classList.add('wide')
          // item.nextElementSibling.classList.remove('hidden')
      }
    })
  }

  function rewriteClassesPrevDirection(arr) {
    arr.forEach((item, index) => {
      if (index === 0) {
        item.classList.remove('wide')
        item.classList.add('narrow')
        item.previousElementSibling.classList.add('wide')
          // item.previousElementSibling.classList.remove('hidden')
        item.previousElementSibling.classList.add('gap')
      }
      if (index === arr.length - 2) {
        item.classList.remove('gap')
      }
      if (index === arr.length - 1) {
        item.classList.remove('narrow')
        item.classList.remove('gap')
          // item.classList.add('hidden')
      }
    })
  }
  window.addEventListener('resize', () => {
    let cur_ww = window.innerWidth
    if (ww > 768 && cur_ww <= 768) {
      destroySlider()
      ww = cur_ww
    } else if (ww <= 768 && cur_ww > 768) {
      initSlider()
      ww = cur_ww
    }
  })
}

//////////////////////////////////////////////////////////
///////////// F O R M S //////////////////////////////////
//////////////////////////////////////////////////////////
// Checkbox-block bottoms
// const checkbox_blocks = document.querySelectorAll('.checkbox-block')
// if (checkbox_blocks) {
//   checkbox_blocks.forEach(el => {
//     el.querySelector('.icon-checkbox').addEventListener('click', onCheckbox)
//   })

//   function onCheckbox(e) {
//     const checkbox = e.currentTarget.nextElementSibling
//     checkbox.checked ? checkbox.checked = false : checkbox.checked = true
//     e.currentTarget.classList.toggle('hidden')
//   }
// }

// Option radios / your question block
// const options = document.querySelectorAll('.option.radio')
// if (options) {
//   options.forEach(el => el.addEventListener('click', onOptionRadio))

//   function onOptionRadio(e) {
//     e.preventDefault()
//     options.forEach(option => option.classList.remove('hidden'))
//     const target = e.currentTarget
//     let radio = target.querySelector('input')
//     if (radio.checked) { return } else { radio.checked = true }
//     const label_text = target.querySelector('label').innerText
//     const select = target.closest('.select')
//     select.querySelector('.cur').innerText = label_text
//     target.classList.add('hidden')
//     const title = select.querySelector('.option.title')
//     title.classList.remove('active')
//     title.nextElementSibling.classList.remove('open')
//   }
// }

// Question accordeons
// const option_titles = document.querySelectorAll('.option.title')
// if (option_titles) {
//   option_titles.forEach(title => title.addEventListener('click', onOptionTitle))

//   function onOptionTitle(e) {
//     const target = e.currentTarget
//     target.classList.toggle('active')
//     target.nextElementSibling.classList.toggle('open')
//   }
// }

//------------- VALIDATE FORM -----------------------//
// import { validateForm } from './_validateform.js'
//----------- FORM CONTACTS ----------------------//
// const form_contacts = document.getElementById('form-contacts')
// if (form_contacts) {
//   validateForm('#form-contacts')
// }

//----------- Inputmask plugin ----------------------//
// import Inputmask from "inputmask"

// const tels = document.querySelectorAll('.tel')
// if (tels) {
//   tels.forEach(tel => Inputmask("+7 (999) 999-9999").mask(tel))
// }

// const emails = document.querySelectorAll('.email')
// if (emails) {
//   emails.forEach(email => Inputmask("email").mask(email))
// }

////////////////////////////////////////////////////////
//////// P O P U P S ///////////////////////////////////
////////////////////////////////////////////////////////
//----------- POPUP CLOSE BUTTONS --------------------//
// const pop_close_btns = document.querySelectorAll('.popup .close')
// if (pop_close_btns) {
//   pop_close_btns.forEach(btn => {
//     btn.addEventListener('click', () => {
//       const dialog_open = document.querySelector('dialog[open]')
//       dialog_open.close()
//     })
//   })
// }

//----------- POPUPS CLOSE ON BACKDROP CLICK --------------------//
// const dialogs = document.querySelectorAll('dialog')
// if (dialogs) {
//   dialogs.forEach(dialog => {
//     dialog.addEventListener("click", e => {
//       const dialogDimensions = dialog.getBoundingClientRect()
//       if (
//         e.clientX < dialogDimensions.left ||
//         e.clientX > dialogDimensions.right ||
//         e.clientY < dialogDimensions.top ||
//         e.clientY > dialogDimensions.bottom
//       ) {
//         dialog.close()
//       }
//     })
//   })
// }

//----------- (1) POPUP REQUEST --------------------//
// const btns_request = document.querySelectorAll('.btn--request')
// if (btns_request) {
//   const popup_request = document.getElementById('popup-request')
//   btns_request.forEach(btn => btn.addEventListener('click', onBtnRequest))

//   function onBtnRequest() {
//     popup_request.showModal()
//   }
// }


//////////////////////////////////////////////////////////////
////////// SEARCH ////////////////////////////////////////////
//////////////////////////////////////////////////////////////

//-------- SEARCH FILTER INPUT -----------------------------//
// let f_inputs = document.querySelectorAll('.filter__input input')
// if (f_inputs) {
//   f_inputs.forEach(input => input.addEventListener('input', onFilterInput))

//   // показывает те элементы списка, названия кот. совпадают с введенными в инпут символами
//   function onFilterInput(e) {
//     let target = e.currentTarget
//       // введенные символы
//     let value = target.value
//       // массив с элементами списка, соответствующий инпуту
//     let items = target.closest('.filter__group').querySelectorAll('.filter__list-item')
//     items.forEach(item => {
//       item.classList.add('hidden')
//       if (item.innerText.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
//         item.classList.remove('hidden')
//       }
//     })
//   }
// }

//////////////////////////////////////////////////////////////
////////// CASES FILTER //////////////////////////////////////
//////////////////////////////////////////////////////////////

let filter_tabs = document.querySelectorAll('.ft-js')
if (filter_tabs) {

  //--- TABS MANIPULATION

  let filters_tablist = document.querySelector('.ftl-js')
  let filter_panels = Array.from(document.querySelectorAll('.fp-js'))
  filter_tabs.forEach(tab => {
    tab.addEventListener('click', (e) => {
      let target = e.currentTarget
      if (target.classList.contains('active')) {
        filters_tablist.classList.remove('active')
        target.classList.remove('active')
        let linked_panel = getLinkedPanel(target)
        linked_panel[0].classList.remove('expanded')
        return
      }
      reset()
      setTimeout(() => {
        filters_tablist.classList.add('active')
        target.classList.add('active')
        target.setAttribute('aria-selected', 'true')
        let linked_panel = getLinkedPanel(target)
        linked_panel[0].classList.add('expanded')
      }, 500)
    })
  })

  function reset() {
    filters_tablist.classList.remove('active')
    filter_tabs.forEach(tab => {
      tab.classList.remove('active')
      tab.setAttribute('aria-selected', 'false')
      let linked_panel = getLinkedPanel(tab)
      linked_panel[0].classList.remove('expanded')
    })
  }

  function getLinkedPanel(tab) {
    let tabId = tab.getAttribute('id')
    let linked_panel = filter_panels.filter(panel => panel.getAttribute('aria-labelledby') === tabId)
    return linked_panel
  }

  //--- OPTIONS MANIPULATION

  let cards = Array.from(document.querySelectorAll('.cases-page .card-case-page'))
  let filter_options = document.querySelectorAll('.filter-option')

  let tags_to_filter = []

  filter_options.forEach(option => option.addEventListener('click', onOption))

  function onOption(e) {
    let target = e.currentTarget
    if (checkFirstOption(target)) {
      if (target.classList.contains('active')) {
        target.classList.remove('active')
        fillArrayWithTagsToFilter()
        filterCards()
        return
      }
      handleFirstOption(target)
      fillArrayWithTagsToFilter()
      filterCards()
      return
    }
    if (target.classList.contains('active')) {
      target.classList.remove('active')
      fillArrayWithTagsToFilter()
      filterCards()
      return
    }
    disactivateFirstOption(target)
    target.classList.add('active')
    fillArrayWithTagsToFilter()
    filterCards()
  }

  function fillArrayWithTagsToFilter() {
    tags_to_filter = []
    filter_options.forEach(option => {
      if (checkFirstOption(option) === true && option.classList.contains('active')) {
        let parent = option.parentElement
        let options = parent.querySelectorAll('.filter-option')
        options.forEach((item, index) => {
          if (index !== 0) {
            tags_to_filter.push(item.innerText.toLowerCase())
          }
        })
      }
      if (option.classList.contains('active') && checkFirstOption(option) === false) {
        tags_to_filter.push(option.innerText.toLowerCase())
      }
    })
  }

  function filterCards() {
    cards.forEach(card => card.classList.remove('hidden'))
    if (tags_to_filter.length === 0) return
    cards.forEach((card) => {
      let arr_data_for = card.getAttribute("data-for").split(",")
      let new_arr_data_for = arr_data_for.map(item => item.toLowerCase())
      let is_included = true
      for (const tag of new_arr_data_for) {
        if (tags_to_filter.includes(tag)) {
          is_included = true
          break
        } else is_included = false
      }
      if (is_included === false) card.classList.add('hidden')
    })
  }

  function checkFirstOption(target) {
    let parent = target.parentElement
    return target === parent.firstElementChild
  }

  function handleFirstOption(target) {
    let parent = target.parentElement
    let options = parent.querySelectorAll('.filter-option')
    options.forEach((option, index) => {
      option.classList.remove('active')
    })
    target.classList.add('active')
  }

  function disactivateFirstOption(target) {
    let parent = target.parentElement
    if (parent.firstElementChild.classList.contains('active')) {
      parent.firstElementChild.classList.remove('active')
    }
  }
}
